/*
Документація по роботі у шаблоні: 
Документація слайдера: https://swiperjs.com/
Сніппет(HTML): swiper
*/

// Підключаємо слайдер Swiper з node_modules
// При необхідності підключаємо додаткові модулі слайдера, вказуючи їх у {} через кому
// Приклад: { Navigation, Autoplay }
import Swiper from 'swiper';
import { Navigation, Grid, Pagination, Thumbs } from 'swiper/modules';
/*
Основні модулі слайдера:
Navigation, Pagination, Autoplay, 
EffectFade, Lazy, Manipulation
Детальніше дивись https://swiperjs.com/
*/

// Ініціалізація слайдерів
function initSliders() {
  // Список слайдерів
  // Перевіряємо, чи є слайдер на сторінці
  if (document.querySelector('.swiper')) {
    // Вказуємо склас потрібного слайдера
    // Створюємо слайдер
    new Swiper('.swiper', {
      // Вказуємо склас потрібного слайдера
      // Підключаємо модулі слайдера
      // для конкретного випадку
      modules: [Navigation, Pagination],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      spaceBetween: 0,
      //autoHeight: true,
      speed: 800,

      //touchRatio: 0,
      //simulateTouch: false,
      //loop: true,
      //preloadImages: false,
      //lazy: true,

      /*
      // Ефекти
      effect: 'fade',
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      */

      // Пагінація
      /*
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      */

      // Скроллбар
      /*
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },
      */

      // Кнопки "вліво/вправо"
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      },
      /*
      // Брейкпоінти
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
          autoHeight: true,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1268: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      */
      // Події
      on: {},
    });
  }

  if (document.querySelectorAll('.custom-slider').length > 0) {
    const args = {
      modules: [Pagination],
      observer: true,
      observeParents: true,
      slidesPerView: 1.05,
      spaceBetween: 0,
      direction: 'horizontal',
      speed: 800,
      loop: true,
      slideActiveClass: 'slide-active',
      allowTouchMove: true,
      pagination: {
        el: '.js-custom-pagination',
        clickable: true,
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          allowTouchMove: true,
        },
        1024: {
          slidesPerView: 'auto',
          allowTouchMove: false,
        },
      },

      on: {

      },
    };

    document.querySelectorAll('.custom-slider').forEach((e) => {
      let slider = new Swiper(e, args);
    });
  }

  if (document.querySelectorAll('.technologies-slider').length > 0) {
    const args = {
      modules: [Pagination],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      spaceBetween: 8,
      direction: 'horizontal',
      speed: 800,
      loop: true,
      allowTouchMove: true,
      slideActiveClass: 'slide-active',
      virtual: {
        enabled: true,
      },
      pagination: {
        el: '.js-technologies-pagination',
        clickable: true,
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          allowTouchMove: true,
          spaceBetween: 8,
        },
        1024: {
          slidesPerView: 'auto',
          allowTouchMove: false,
          spaceBetween: 0,
        },
      },

      on: {
      },
    };

    document.querySelectorAll('.technologies-slider').forEach((e) => {
      let slider = new Swiper(e, args);
    });
  }

  if (document.querySelectorAll('.auto-section__images').length > 0) {
    let sliderThumbnail = new Swiper('.auto-slider', {
      modules: [Navigation, Pagination, Thumbs],
      slidesPerView: 'auto',
      freeMode: true,
      slideActiveClass: 'slide-active',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      allowTouchMove: false,
    });
    
    let slider = new Swiper('.auto-section__images', {
      modules: [Navigation, Pagination, Thumbs],
      slidesPerView: 'auto',
      direction: 'horizontal',
      slideActiveClass: 'slide-active',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      thumbs: {
        swiper: sliderThumbnail
      }
    })
  }

  if (document.querySelectorAll('.js-releated-slider').length > 0) {
    const args = {
      modules: [Pagination, Navigation],
      observer: true,
      observeParents: true,
      slidesPerView: 1,
      spaceBetween: 16,
      direction: 'horizontal',
      speed: 800,
      loop: true,
      loopFillGroupWithBlank: true,
      slideActiveClass: 'slide-active',

      pagination: {
        el: '.js-releated-pagination',
        clickable: true,
      },

      navigation: {
        prevEl: '.js-releated-prev',
        nextEl: '.js-releated-next',
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      },

      on: {

      },
    };

    document.querySelectorAll('.js-releated-slider').forEach((e) => {
      let slider = new Swiper(e, args);
    });
  }

  if (document.querySelectorAll('.js-solution-slider').length > 0) {
    const args = {
      modules: [Pagination],
      observer: true,
      observeParents: true,
      slidesPerView: 1.05,
      spaceBetween: 8,
      direction: 'horizontal',
      speed: 800,
      loop: true,
      slideActiveClass: 'slide-active',
      allowTouchMove: true,
      pagination: {
        el: '.js-solution-pagination',
        clickable: true,
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          allowTouchMove: true,
          spaceBetween: 8,
        },
        1024: {
          slidesPerView: 'auto',
          allowTouchMove: false,
          spaceBetween: 0,
        },
      },

      on: {
      },
    };

    document.querySelectorAll('.js-solution-slider').forEach((e) => {
      let slider = new Swiper(e, args);
    });
  }

  if (document.querySelectorAll('.js-accessories-slider').length > 0) {
    const args = {
      modules: [Pagination],
      observer: true,
      observeParents: true,
      slidesPerView: 1.05,
      spaceBetween: 8,
      direction: 'horizontal',
      speed: 800,
      loop: true,
      slideActiveClass: 'slide-active',

      pagination: {
        el: '.js-accessories-pagination',
        clickable: true,
      },

      breakpoints: {
        768: {
          slidesPerView: 2,
          allowTouchMove: true,
          spaceBetween: 8,
        },
        1024: {
          slidesPerView: 'auto',
          allowTouchMove: false,
          spaceBetween: 0,
        },
      },

      on: {
      },
    };

    document.querySelectorAll('.js-accessories-slider').forEach((e) => {
      let slider = new Swiper(e, args);
    });
  }
}
// Скролл на базі слайдера (за класом swiper scroll для оболонки слайдера)
function initSlidersScroll() {
  let sliderScrollItems = document.querySelectorAll('.swiper_scroll');
  if (sliderScrollItems.length > 0) {
    for (let index = 0; index < sliderScrollItems.length; index++) {
      const sliderScrollItem = sliderScrollItems[index];
      const sliderScrollBar =
        sliderScrollItem.querySelector('.swiper-scrollbar');
      const sliderScroll = new Swiper(sliderScrollItem, {
        observer: true,
        observeParents: true,
        direction: 'vertical',
        slidesPerView: 'auto',
        freeMode: {
          enabled: true,
        },
        scrollbar: {
          el: sliderScrollBar,
          draggable: true,
          snapOnRelease: false,
        },
        mousewheel: {
          releaseOnEdges: true,
        },
      });
      sliderScroll.scrollbar.updateSize();
    }
  }
}

window.addEventListener('load', function (e) {
  // Запуск ініціалізації слайдерів
  initSliders();
  // Запуск ініціалізації скролла на базі слайдера (за класом swiper_scroll)
  //initSlidersScroll();
});