// Підключення функціоналу "Чертоги Фрілансера"
import { isMobile } from './functions.js';
// Підключення списку активних модулів
import { flsModules } from './modules.js';

document.querySelectorAll('.technologies-card').forEach((item) => {
  item.addEventListener('mouseover', function () {
    const images = document.querySelectorAll('.technologies-section__img');
    const imageSrc = this.querySelector('img').src;
    images.forEach((img) => {
      img.style.backgroundImage = `url('${imageSrc}')`;
    });
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const openPopupButton = document.querySelector('.js-open-popup');
  const mask = document.querySelector('.js-mask');
  const html = document.querySelector('html');
  const popup = document.querySelector('.js-popup');
  const closePopupButtons = document.querySelectorAll(
    '.js-close-popup, .js-popup-close'
  );

  function openPopup() {
    mask.classList.add('active');
    popup.classList.add('active');
    html.style.overflow = 'hidden';
  }

  function closePopup() {
    mask.classList.remove('active');
    popup.classList.remove('active');
    html.style.overflow = '';
  }

  if (openPopupButton) {
    openPopupButton.addEventListener('click', openPopup);
  }

  if (mask) {
    mask.addEventListener('click', closePopup);
  }

  if (closePopupButtons) {
    closePopupButtons.forEach(function (button) {
      button.addEventListener('click', closePopup);
    });
  }

  const filterBtn = document.querySelector('.js-filter-btn');
  const navigation = document.querySelector('.js-navigation');

  function handleClickOutside(event) {
    if (!filterBtn.contains(event.target) && !navigation.contains(event.target)) {
      filterBtn.classList.remove('active');
      navigation.classList.remove('active');
      document.removeEventListener('click', handleClickOutside);
    }
  }
  if (filterBtn){
    filterBtn.addEventListener('click', function () {
      this.classList.toggle('active');
      navigation.classList.toggle('active');
      document.addEventListener('click', handleClickOutside);
    });
  }
  
});
